<template>
  <v-app>
    <div class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-y-scroll">
      <div class="d-flex flex-column-fluid flex-center mt-lg-10 mt-lg-0">
        <div class="d-flex flex-column align-items-center align-center text-center ">
          <h1>Entrance Exam Result</h1>
            <p class="text-left mt-10 mb-10">To check your entrance exam result, please enter your entrance exam Enrolment No and  Enrolment PIN. These information can be found on the admit card provided to you via email or college administration. </p>
          <v-app>
            <v-row>


              <v-col cols="12">
                <v-text-field label="Enrolment No"
                              :error="$v.login.symbol_no.$error"
                              v-model.trim="login.symbol_no" outlined
                              hide-details>

                </v-text-field>
                <span class="text-danger" v-if="$v.login.symbol_no.$error">Enrolment No is required</span>
              </v-col>

              <v-col cols="12">
                  <v-text-field label="Enrolment PIN" :error="$v.login.enrolment_pin.$error"
                                v-model.trim="login.enrolment_pin" outlined
                                hide-details>

                  </v-text-field>
                  <span class="text-danger" v-if=" $v.login.enrolment_pin.$error">Enrolment PIN is required</span>
              </v-col>
                <router-link
                        href="#"
                        class="text-dark-60 text-hover-primary my-3 mr-2"
                        id="kt_login_forgot"
                        :to="{ name: 'forgot-enrolment-pin' }"
                >Forgot Enrolment PIN
                </router-link>

              <v-col cols="12" class="text-right">
                <v-btn @click.prevent="redirectTo" depressed class=" btn btn-standard ml-2" dark>
                  Back
                </v-btn>
                <v-btn :loading="isLoading" @click.prevent="userEnrollmentLogin" depressed class="btn btn-primary btn-continue ml-2" dark>
                      Login
                </v-btn>
              </v-col>
            </v-row>
          </v-app>
            <vue-recaptcha
                    ref="invisibleRecaptcha"
                    :sitekey="sitekey"
                    @verify="onCaptchaVerified"
                    :loadRecaptchaScript="true"
                    size="invisible"
            ></vue-recaptcha>
        </div>
      </div>
    </div>
  </v-app>

</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import EssentialService from "@/core/services/enrollment/EssentialService";
import VueRecaptcha from "vue-recaptcha";
const enrollmentEssentialService=new EssentialService();
export default {
  name: "Index",
    components: {VueRecaptcha},
  validations() {
    return {
      login: {
        symbol_no: {required},
        enrolment_pin: {required},

      },
    }

  },
  data() {
    return {

      isLoading: false,
      login: {
        symbol_no: null,
          enrolment_pin: null,
      }
    }
  },
  methods: {
    onCaptchaVerified(){
        this.$v.login.$touch();
        if (this.$v.login.$error) {
            setTimeout(() => {
                this.$v.login.$reset();
            }, 3000);
        } else {
            this.userEnrollmentLogin();
        }
    },
    loginUserEnrollment() {
        this.$refs.invisibleRecaptcha.execute();
    },
    userEnrollmentLogin(){
        enrollmentEssentialService
            .userEnrollmentLogin(this.login)
            .then(response => {
                if(response.data.status=="OK" && response.data.application_key){
                    localStorage.setItem('application_key', response.data.application_key);
                    if (localStorage.hasOwnProperty('application_key')) {
                        this.$router.push({
                            name: 'enrollment-profile',
                        })
                    }
                }
                if(response.data.status=='ERROR'){
                    this.$snotify.error(response.data.errors);
                }
            })
            .catch((err) => {

            })
            .finally(()=>{

            });
    },
    redirectTo() {
      this.$router.push({name: 'index'});
    },


  },
  computed:{
     sitekey :function () {
         return this.$store.getters.siteKey;
     },
  }
}
</script>

<style scoped>
.datepicker > input {
  width: 100%;
}
</style>
